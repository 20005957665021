export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/__layout-empty.svelte"),
	() => import("../../src/routes/__layout-full.svelte"),
	() => import("../../src/routes/3pl/dashboard.svelte"),
	() => import("../../src/routes/3pl/monitor.svelte"),
	() => import("../../src/routes/accounting/index.svelte"),
	() => import("../../src/routes/admin/config/manage-colors.svelte"),
	() => import("../../src/routes/admin/config/manage-sizes.svelte"),
	() => import("../../src/routes/admin/config/users.svelte"),
	() => import("../../src/routes/admin/dev.svelte"),
	() => import("../../src/routes/admin/manage-brands.svelte"),
	() => import("../../src/routes/admin/manage-notifications.svelte"),
	() => import("../../src/routes/admin/operations-dashboard.svelte"),
	() => import("../../src/routes/admin/shopline-actions.svelte"),
	() => import("../../src/routes/admin/tools/index.svelte"),
	() => import("../../src/routes/admin/tools/remap-barcode.svelte"),
	() => import("../../src/routes/auth/sign-in.svelte"),
	() => import("../../src/routes/buyer/[brandSlug].svelte"),
	() => import("../../src/routes/buyer/index.svelte"),
	() => import("../../src/routes/captions/[uuid]@empty.svelte"),
	() => import("../../src/routes/captions/index@empty.svelte"),
	() => import("../../src/routes/captions/language.svelte"),
	() => import("../../src/routes/captions/overlay/[uuid]@empty.svelte"),
	() => import("../../src/routes/captions/overlay/index@empty.svelte"),
	() => import("../../src/routes/customs/customs-forms.svelte"),
	() => import("../../src/routes/customs/forms/[dateString].svelte"),
	() => import("../../src/routes/customs/shipment/[dateString].svelte"),
	() => import("../../src/routes/customs/shipments/inbound.svelte"),
	() => import("../../src/routes/customs/taiwan-customs/fees/[dateString].svelte"),
	() => import("../../src/routes/customs/taiwan-customs/fees/fees-archive/[dateString].svelte"),
	() => import("../../src/routes/customs/taiwan-customs/payments.svelte"),
	() => import("../../src/routes/customs/taiwan-customs.svelte"),
	() => import("../../src/routes/dashboards/executive@full.svelte"),
	() => import("../../src/routes/dashboards/marketing@full.svelte"),
	() => import("../../src/routes/finance/expensify-geo.svelte"),
	() => import("../../src/routes/finance/expensify.svelte"),
	() => import("../../src/routes/finance/log-balance.svelte"),
	() => import("../../src/routes/fulfillment/add-inventory/[brandSlug]/[streamerSlug].svelte"),
	() => import("../../src/routes/fulfillment/add-inventory/[brandSlug]/select-streamer.svelte"),
	() => import("../../src/routes/fulfillment/add-inventory/all/[streamerSlug].svelte"),
	() => import("../../src/routes/fulfillment/add-inventory/all/select-streamer.svelte"),
	() => import("../../src/routes/fulfillment/add-inventory/select-brand.svelte"),
	() => import("../../src/routes/fulfillment/fulfill/[brandSlug].svelte"),
	() => import("../../src/routes/fulfillment/gift-cards/[giftCardBarcode].svelte"),
	() => import("../../src/routes/fulfillment/gift-cards/add.svelte"),
	() => import("../../src/routes/fulfillment/gift-cards/index.svelte"),
	() => import("../../src/routes/fulfillment/gift-cards/search.svelte"),
	() => import("../../src/routes/fulfillment/menu.svelte"),
	() => import("../../src/routes/fulfillment/receiving/drop-off/[fulfillmentRequestBarcode]@empty.svelte"),
	() => import("../../src/routes/fulfillment/receiving.svelte"),
	() => import("../../src/routes/fulfillment/requests/[fulfillmentRequestBarcode].svelte"),
	() => import("../../src/routes/fulfillment/requests.svelte"),
	() => import("../../src/routes/fulfillment/select-brand.svelte"),
	() => import("../../src/routes/fulfillment/streamer/[streamerSlug].svelte"),
	() => import("../../src/routes/fulfillment/tracking/index.svelte"),
	() => import("../../src/routes/fulfillment/tracking/receipt/[onlineOrderNumber].svelte"),
	() => import("../../src/routes/fulfillment/tracking/tracking-number/[trackingNumber].svelte"),
	() => import("../../src/routes/fulfillment.svelte"),
	() => import("../../src/routes/html-to-pdf/3pl-invoice/[year]/[month]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/delicious-milk-invoice/[invoiceId]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/delicious-milk-invoice/wire-transfer-invoice/legacy/[invoiceId]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/fulfillment-requests/pickup-sheet/[fulfillmentRequestBarcode]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/invalid@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/order-invoice/[orderNumber]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/seven-eleven-label/[orderNumber]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/warehouse-activity-chart/[date]/[metric]/[agent]@empty.svelte"),
	() => import("../../src/routes/html-to-pdf/warehouse-activity-chart/[date]/[metric]/all@empty.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/inventory/binning/bin/[binBarcode].svelte"),
	() => import("../../src/routes/inventory/binning/clear-bin.svelte"),
	() => import("../../src/routes/inventory/binning/find-bin.svelte"),
	() => import("../../src/routes/inventory/binning/v2/scan-item.svelte"),
	() => import("../../src/routes/inventory/binning/v2/session/[binningSessionId].svelte"),
	() => import("../../src/routes/inventory/binning/v3/scan-item.svelte"),
	() => import("../../src/routes/inventory/binning/v3/session/[binningSessionId].svelte"),
	() => import("../../src/routes/inventory/manage.svelte"),
	() => import("../../src/routes/livestream/companion/[streamId].svelte"),
	() => import("../../src/routes/livestream/companion/index.svelte"),
	() => import("../../src/routes/livestream/companion/overlay/[streamerSlug]@empty.svelte"),
	() => import("../../src/routes/livestream/livestream-products.svelte"),
	() => import("../../src/routes/livestream/livestream-url.svelte"),
	() => import("../../src/routes/livestream/overlay/announcements/[streamerSlug]@empty.svelte"),
	() => import("../../src/routes/livestream/overlay/product-images/[streamerSlug]@empty.svelte"),
	() => import("../../src/routes/livestream/overlays/[streamerSlug]/[overlaySlug]@empty.svelte"),
	() => import("../../src/routes/livestream/products/timestamps.svelte"),
	() => import("../../src/routes/livestream/tools/video-upload.svelte"),
	() => import("../../src/routes/modules.svelte"),
	() => import("../../src/routes/notifications/push.svelte"),
	() => import("../../src/routes/orders/print-label.svelte"),
	() => import("../../src/routes/pack-box/v2-items/box/[boxBarcode]/unload-package/[trackingNumber].svelte"),
	() => import("../../src/routes/pack-box/v2-items/box/[boxBarcode].svelte"),
	() => import("../../src/routes/pack-box/v2-items/find-box.svelte"),
	() => import("../../src/routes/pack-order/v2/find-order.svelte"),
	() => import("../../src/routes/pack-order/v2/order/[orderNumber].svelte"),
	() => import("../../src/routes/pick-order/[location]/order/[orderNumber].svelte"),
	() => import("../../src/routes/pick-order/menu.svelte"),
	() => import("../../src/routes/pick-order/order/[orderNumber].svelte"),
	() => import("../../src/routes/pick-order/order/label/[orderNumber]@empty.svelte"),
	() => import("../../src/routes/pick-order/orders/[orderList].svelte"),
	() => import("../../src/routes/playground.svelte"),
	() => import("../../src/routes/playground2.svelte"),
	() => import("../../src/routes/playground3.svelte"),
	() => import("../../src/routes/products/classify.svelte"),
	() => import("../../src/routes/refulfillment/brand/[brandSlug].svelte"),
	() => import("../../src/routes/refulfillment/manage-purchasing-accounts.svelte"),
	() => import("../../src/routes/refulfillment/menu.svelte"),
	() => import("../../src/routes/refulfillment/order/[orderNumber].svelte"),
	() => import("../../src/routes/refulfillment/scan-order.svelte"),
	() => import("../../src/routes/refulfillment/select-brand.svelte"),
	() => import("../../src/routes/refulfillment/view.svelte"),
	() => import("../../src/routes/reporting/dashboards/executive.svelte"),
	() => import("../../src/routes/reporting/dashboards/unit-economics.svelte"),
	() => import("../../src/routes/reporting/kpis.svelte"),
	() => import("../../src/routes/samples/generate-list/[brandId].svelte"),
	() => import("../../src/routes/samples/select-brand.svelte"),
	() => import("../../src/routes/search/results/box/[boxBarcode].svelte"),
	() => import("../../src/routes/search/results/item/[productVariantBarcode].svelte"),
	() => import("../../src/routes/search/results/order/[orderNumber].svelte"),
	() => import("../../src/routes/search/search-box.svelte"),
	() => import("../../src/routes/search/search-item.svelte"),
	() => import("../../src/routes/search/search-order.svelte"),
	() => import("../../src/routes/shipments/invoices/[dateString].svelte"),
	() => import("../../src/routes/sign-in.svelte"),
	() => import("../../src/routes/support/index.svelte"),
	() => import("../../src/routes/tools/caption-config.svelte"),
	() => import("../../src/routes/tools/syncing-tools.svelte"),
	() => import("../../src/routes/user/modify-user.svelte"),
	() => import("../../src/routes/warehouse/taiwan/receiving.svelte"),
	() => import("../../src/routes/warehouse/taiwan/unpack-box.svelte"),
	() => import("../../src/routes/xml/amyshop.svelte"),
	() => import("../../src/routes/xml/menu.svelte"),
	() => import("../../src/routes/xml/shop/[xmlKey].svelte")
];

export const dictionary = {
	"": [[0, 68], [1]],
	"accounting": [[0, 6], [1]],
	"buyer": [[0, 19], [1]],
	"captions@empty": [[2, 21], [1]],
	"fulfillment": [[0, 58], [1]],
	"modules": [[0, 87], [1]],
	"playground": [[0, 100], [1]],
	"playground2": [[0, 101], [1]],
	"playground3": [[0, 102], [1]],
	"sign-in": [[0, 123], [1]],
	"support": [[0, 124], [1]],
	"3pl/dashboard": [[0, 4], [1]],
	"3pl/monitor": [[0, 5], [1]],
	"admin/dev": [[0, 10], [1]],
	"admin/manage-brands": [[0, 11], [1]],
	"admin/manage-notifications": [[0, 12], [1]],
	"admin/operations-dashboard": [[0, 13], [1]],
	"admin/shopline-actions": [[0, 14], [1]],
	"admin/tools": [[0, 15], [1]],
	"auth/sign-in": [[0, 17], [1]],
	"captions/language": [[0, 22], [1]],
	"captions/overlay@empty": [[2, 24], [1]],
	"customs/customs-forms": [[0, 25], [1]],
	"customs/taiwan-customs": [[0, 32], [1]],
	"dashboards/executive@full": [[3, 33], [1]],
	"dashboards/marketing@full": [[3, 34], [1]],
	"finance/expensify-geo": [[0, 35], [1]],
	"finance/expensify": [[0, 36], [1]],
	"finance/log-balance": [[0, 37], [1]],
	"fulfillment/gift-cards": [[0, 46], [1]],
	"fulfillment/menu": [[0, 48], [1]],
	"fulfillment/receiving": [[0, 50], [1]],
	"fulfillment/requests": [[0, 52], [1]],
	"fulfillment/select-brand": [[0, 53], [1]],
	"fulfillment/tracking": [[0, 55], [1]],
	"html-to-pdf/invalid@empty": [[2, 63], [1]],
	"inventory/manage": [[0, 76], [1]],
	"livestream/companion": [[0, 78], [1]],
	"livestream/livestream-products": [[0, 80], [1]],
	"livestream/livestream-url": [[0, 81], [1]],
	"notifications/push": [[0, 88], [1]],
	"orders/print-label": [[0, 89], [1]],
	"pick-order/menu": [[0, 96], [1]],
	"products/classify": [[0, 103], [1]],
	"refulfillment/manage-purchasing-accounts": [[0, 105], [1]],
	"refulfillment/menu": [[0, 106], [1]],
	"refulfillment/scan-order": [[0, 108], [1]],
	"refulfillment/select-brand": [[0, 109], [1]],
	"refulfillment/view": [[0, 110], [1]],
	"reporting/kpis": [[0, 113], [1]],
	"samples/select-brand": [[0, 115], [1]],
	"search/search-box": [[0, 119], [1]],
	"search/search-item": [[0, 120], [1]],
	"search/search-order": [[0, 121], [1]],
	"tools/caption-config": [[0, 125], [1]],
	"tools/syncing-tools": [[0, 126], [1]],
	"user/modify-user": [[0, 127], [1]],
	"xml/amyshop": [[0, 130], [1]],
	"xml/menu": [[0, 131], [1]],
	"admin/config/manage-colors": [[0, 7], [1]],
	"admin/config/manage-sizes": [[0, 8], [1]],
	"admin/config/users": [[0, 9], [1]],
	"admin/tools/remap-barcode": [[0, 16], [1]],
	"customs/shipments/inbound": [[0, 28], [1]],
	"customs/taiwan-customs/payments": [[0, 31], [1]],
	"fulfillment/add-inventory/select-brand": [[0, 42], [1]],
	"fulfillment/gift-cards/add": [[0, 45], [1]],
	"fulfillment/gift-cards/search": [[0, 47], [1]],
	"inventory/binning/clear-bin": [[0, 70], [1]],
	"inventory/binning/find-bin": [[0, 71], [1]],
	"livestream/products/timestamps": [[0, 85], [1]],
	"livestream/tools/video-upload": [[0, 86], [1]],
	"pack-box/v2-items/find-box": [[0, 92], [1]],
	"pack-order/v2/find-order": [[0, 93], [1]],
	"reporting/dashboards/executive": [[0, 111], [1]],
	"reporting/dashboards/unit-economics": [[0, 112], [1]],
	"warehouse/taiwan/receiving": [[0, 128], [1]],
	"warehouse/taiwan/unpack-box": [[0, 129], [1]],
	"fulfillment/add-inventory/all/select-streamer": [[0, 41], [1]],
	"inventory/binning/v2/scan-item": [[0, 72], [1]],
	"inventory/binning/v3/scan-item": [[0, 74], [1]],
	"html-to-pdf/delicious-milk-invoice/wire-transfer-invoice/legacy/[invoiceId]@empty": [[2, 61], [1]],
	"customs/taiwan-customs/fees/fees-archive/[dateString]": [[0, 30], [1]],
	"inventory/binning/v2/session/[binningSessionId]": [[0, 73], [1]],
	"inventory/binning/v3/session/[binningSessionId]": [[0, 75], [1]],
	"fulfillment/receiving/drop-off/[fulfillmentRequestBarcode]@empty": [[2, 49], [1]],
	"html-to-pdf/fulfillment-requests/pickup-sheet/[fulfillmentRequestBarcode]@empty": [[2, 62], [1]],
	"livestream/companion/overlay/[streamerSlug]@empty": [[2, 79], [1]],
	"livestream/overlay/announcements/[streamerSlug]@empty": [[2, 82], [1]],
	"livestream/overlay/product-images/[streamerSlug]@empty": [[2, 83], [1]],
	"pick-order/order/label/[orderNumber]@empty": [[2, 98], [1]],
	"customs/taiwan-customs/fees/[dateString]": [[0, 29], [1]],
	"fulfillment/add-inventory/all/[streamerSlug]": [[0, 40], [1]],
	"fulfillment/tracking/receipt/[onlineOrderNumber]": [[0, 56], [1]],
	"fulfillment/tracking/tracking-number/[trackingNumber]": [[0, 57], [1]],
	"inventory/binning/bin/[binBarcode]": [[0, 69], [1]],
	"pack-box/v2-items/box/[boxBarcode]": [[0, 91], [1]],
	"pack-order/v2/order/[orderNumber]": [[0, 94], [1]],
	"search/results/box/[boxBarcode]": [[0, 116], [1]],
	"search/results/item/[productVariantBarcode]": [[0, 117], [1]],
	"search/results/order/[orderNumber]": [[0, 118], [1]],
	"pack-box/v2-items/box/[boxBarcode]/unload-package/[trackingNumber]": [[0, 90], [1]],
	"captions/overlay/[uuid]@empty": [[2, 23], [1]],
	"html-to-pdf/delicious-milk-invoice/[invoiceId]@empty": [[2, 60], [1]],
	"html-to-pdf/order-invoice/[orderNumber]@empty": [[2, 64], [1]],
	"html-to-pdf/seven-eleven-label/[orderNumber]@empty": [[2, 65], [1]],
	"customs/forms/[dateString]": [[0, 26], [1]],
	"customs/shipment/[dateString]": [[0, 27], [1]],
	"fulfillment/fulfill/[brandSlug]": [[0, 43], [1]],
	"fulfillment/gift-cards/[giftCardBarcode]": [[0, 44], [1]],
	"fulfillment/requests/[fulfillmentRequestBarcode]": [[0, 51], [1]],
	"fulfillment/streamer/[streamerSlug]": [[0, 54], [1]],
	"livestream/companion/[streamId]": [[0, 77], [1]],
	"pick-order/order/[orderNumber]": [[0, 97], [1]],
	"pick-order/orders/[orderList]": [[0, 99], [1]],
	"refulfillment/brand/[brandSlug]": [[0, 104], [1]],
	"refulfillment/order/[orderNumber]": [[0, 107], [1]],
	"samples/generate-list/[brandId]": [[0, 114], [1]],
	"shipments/invoices/[dateString]": [[0, 122], [1]],
	"xml/shop/[xmlKey]": [[0, 132], [1]],
	"fulfillment/add-inventory/[brandSlug]/select-streamer": [[0, 39], [1]],
	"html-to-pdf/3pl-invoice/[year]/[month]@empty": [[2, 59], [1]],
	"livestream/overlays/[streamerSlug]/[overlaySlug]@empty": [[2, 84], [1]],
	"fulfillment/add-inventory/[brandSlug]/[streamerSlug]": [[0, 38], [1]],
	"html-to-pdf/warehouse-activity-chart/[date]/[metric]/all@empty": [[2, 67], [1]],
	"html-to-pdf/warehouse-activity-chart/[date]/[metric]/[agent]@empty": [[2, 66], [1]],
	"captions/[uuid]@empty": [[2, 20], [1]],
	"buyer/[brandSlug]": [[0, 18], [1]],
	"pick-order/[location]/order/[orderNumber]": [[0, 95], [1]]
};